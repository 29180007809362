import React, { useEffect, useState } from 'react';
import Camera from '../../../components/Camera/Camera';
import './PlayerForm.scss';

export default function PlayerForm({
    name = '',
    image,
    onSave,
    onDelete,
    onClose,
}) {
    const [formName, setFormName] = useState(name);
    const [formImage, setformImage] = useState(image);
    let nameInput = null;

    const onCameraClick = (image) => {
        setformImage(image);
    };

    const handleClose = () => {
        setFormName('');
        onClose();
    };

    const handleKeyUp = async (e) => {
        if (e.key === 'Enter') {
            handleSave();
        }
    };

    const handleDelete = () => {
        if (window.confirm(`Vill du ta bort ${formName}?`)) {
            setFormName('');
            onDelete();
        }
    };

    const handleSave = () => {
        onSave(formName, formImage);
        setFormName('');
    };

    useEffect(() => {
        nameInput?.focus();
    }, [nameInput]);

    useEffect(() => {
        function handleEscape(e) {
            if (e.key === 'Escape') {
                setFormName('');
                onClose();
            }
        }
        window.addEventListener('keyup', handleEscape);
        return () => window.removeEventListener('keyup', handleEscape);
    }, [onClose]);

    return (
        <div className='player-form'>
            <div className='close-btn' onClick={() => handleClose()}>
                X
            </div>
            <Camera onClick={onCameraClick} image={formImage} />
            <input
                ref={(input) => {
                    nameInput = input;
                }}
                type='text'
                value={formName}
                placeholder='Namn'
                onKeyUp={handleKeyUp}
                onChange={(evt) => setFormName(evt.target.value)}
            />
            <div className='buttons'>
                {onDelete && (
                    <button onClick={handleDelete}>
                        <i className='fas fa-trash-alt' /> Ta bort spelare
                    </button>
                )}
                <button onClick={handleSave}>
                    <i className='fas fa-save' /> Spara
                </button>
            </div>
        </div>
    );
}

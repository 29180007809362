import React from 'react';
import { printScore, sum } from '../../../utils';
import './ScoreTotal.scss';

export default function ScoreTotal({ players, course }) {
    return (
        <tr>
            <th className='total'>
                <div>TOTAL</div>
                <div>
                    PAR:{' '}
                    {course?.holes?.reduce((acc, hole) => acc + hole.par, 0)}
                </div>
            </th>
            {players.map((player, i) => (
                <th key={i} className='player-score'>
                    <div>{printScore(player.score, course)}</div>
                    <div>({player.score.reduce(sum)})</div>
                    <div>
                        {player.score.slice(0, 9).reduce(sum)} /{' '}
                        {player.score.slice(9).reduce(sum)}
                    </div>
                </th>
            ))}
        </tr>
    );
}

import React, { useContext, useState } from 'react';
import './Scorecard.scss';
import { deepClone, times } from '../../utils';
import AddPlayer from './AddPlayer/AddPlayer';
import Score from './Score/Score';
import ScoreTotal from './ScoreTotal/ScoreTotal';
import Hole from './Hole/Hole';
import { DeleteRound, SaveRound } from '../../data/apiClient';
import PlayerForm from './PlayerForm/PlayerForm';
import DateForm from './DateForm/DateForm';
import GolfContext from '../../GolfContext';

function createId(len = 6, chars = 'abcdefghjkmnopqrstvwxyz01234567890') {
    let id = '';
    while (len--) {
        id += chars[(Math.random() * chars.length) | 0];
    }
    return id;
}

export default function Scorecard({ round, onBack }) {
    const { courses, user } = useContext(GolfContext);
    const { courseId } = round;

    const course = courses.find((c) => c.id === courseId);
    const [id] = useState(round.id || createId());
    const [terminated, setTerminated] = useState(round.terminated || false);
    const [date, setDate] = useState(round.date || new Date().toLocaleString());
    const [players, setPlayers] = useState(deepClone(round.players) || []);
    const [editPlayer, setEditPlayer] = useState(null);
    const [editDate, setEditDate] = useState(null);

    if (courseId === undefined) return null;

    async function deleteRound(evt) {
        if (window.confirm('Säker?')) {
            if (terminated) {
                await DeleteRound(id);
                onBack(true);
            } else {
                localStorage.removeItem(`round-${id}`);
                onBack();
            }
        }
    }

    function back(evt) {
        if (!terminated) save();
        onBack();
    }

    function save() {
        localStorage.setItem(
            `round-${id}`,
            JSON.stringify({
                id,
                username: user.username,
                date,
                courseId: round.courseId,
                players,
                terminated,
            })
        );
    }

    async function terminateRound(evt) {
        var data = JSON.stringify({
            id,
            username: user.username,
            date,
            courseId: round.courseId,
            players,
            terminated: true,
        });
        if (await SaveRound(data)) {
            alert('Runda avslutad');
            localStorage.removeItem(`round-${id}`);
            onBack(true);
        } else {
            alert('Gick inte att spara rundan. Försök igen senare');
        }
    }

    function unlockRound() {
        setTerminated(false);
        save();
    }

    function setScore(score, hole, playerIdx) {
        const _players = deepClone(players);
        _players[playerIdx].score[hole] = Number(score);
        setPlayers(_players);
        save();
    }

    function addPlayer(name, image) {
        setPlayers([...players, { name, image, score: Array(18).fill(0) }]);
        save();
    }

    function deletePlayer() {
        players.splice(editPlayer, 1);
        setEditPlayer(null);
        save();
    }

    function updatePlayer(name, image) {
        players[editPlayer].name = name;
        players[editPlayer].image = image;
        setEditPlayer(null);
        save();
    }

    function updateDate(newDate) {
        setDate(newDate);
        setEditDate(null);
        save();
    }

    function renderPlayer(player, i) {
        if (player.image) {
            return (
                <img
                    src={player.image}
                    className='player-image'
                    onClick={() => setEditPlayer(i)}
                    alt={player.name}
                />
            );
        }
        return (
            <span className='player-name' onClick={() => setEditPlayer(i)}>
                {player.name}
            </span>
        );
    }

    return (
        <div className='scorecard'>
            <div className='header'>
                {editPlayer !== null && (
                    <PlayerForm
                        name={players[editPlayer].name}
                        image={players[editPlayer].image}
                        onClose={() => setEditPlayer(null)}
                        onDelete={deletePlayer}
                        onSave={updatePlayer}
                    />
                )}
                {!terminated && <AddPlayer onAdd={addPlayer} />}
                <span className='btn-back' onClick={back}>
                    &lt;
                </span>
                <h1>{course && course.name}</h1>
                {editDate !== null && (
                    <DateForm
                        date={editDate}
                        onCancel={() => setEditDate(null)}
                        onSave={updateDate}
                    />
                )}
                <p onClick={() => setEditDate(date)}>
                    {date} {terminated && <i className='fas fa-lock'></i>}
                </p>
            </div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        {times(players.length, (i) => (
                            <th key={i}>{renderPlayer(players[i], i)}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {course?.holes?.map((hole) => {
                        return (
                            <tr key={`row${-hole.no}`}>
                                <Hole
                                    key={hole.no}
                                    number={hole.no}
                                    par={hole.par}
                                    distance={hole.distance}
                                    mapUrl={hole.url}
                                />
                                {players.map((player, playerIdx) => (
                                    <Score
                                        key={`p${playerIdx}${-hole}`}
                                        onSetScore={(score) =>
                                            setScore(
                                                score,
                                                hole.no - 1,
                                                playerIdx
                                            )
                                        }
                                        score={player.score[hole.no - 1]}
                                        par={hole.par}
                                        terminated={terminated}
                                    />
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <ScoreTotal players={players} course={course} />
                </tfoot>
            </table>
            <div className='buttons-container'>
                <div className='buttons'>
                    {terminated ? (
                        <button className='btn-unlock' onClick={unlockRound}>
                            Återuppta runda
                        </button>
                    ) : (
                        <button
                            className='btn-terminate'
                            onClick={terminateRound}
                        >
                            <i className='fas fa-check' /> Avsluta runda
                        </button>
                    )}
                    <button className='btn-delete' onClick={deleteRound}>
                        <i className='fas fa-trash-alt' /> Ta bort runda
                    </button>
                </div>
            </div>
        </div>
    );
}

import React, { useContext, useEffect, useState } from 'react';
import Menu from '../../components/Menu/Menu';
import GolfContext from '../../GolfContext';
import './UserView.scss';
import Camera from '../../components/Camera/Camera';

export default function UserView() {
    const { user, updateUser } = useContext(GolfContext);
    const [state, setState] = useState({
        username: user.username,
        image: user.image,
    });

    const handleKeyUp = async (e) => {
        if (e.key === 'Enter') {
            updateUser({ username: state.username });
        }
    };

    const onCameraClick = (image) => {
        setState({ ...state, image });
        updateUser({ image });
    };

    useEffect(() => {
        const storedUserString = localStorage.getItem('user');
        const userString = JSON.stringify(user);
        if (storedUserString && storedUserString !== userString) {
            const storedUser = JSON.parse(storedUserString);
            setState({
                ...state,
                username: storedUser.username,
                image: storedUser.image,
            });
            updateUser(storedUser);
        }
    }, [user, updateUser, state]);

    return (
        <>
            <div className='user-view'>
                <h1>Hej, {user.username}</h1>
                <Camera onClick={onCameraClick} image={state.image} />
                <div className='username-form'>
                    <h3>Välj användarnamn</h3>
                    <input
                        type='text'
                        value={state.username}
                        placeholder='Namn'
                        onKeyUp={handleKeyUp}
                        onChange={(evt) =>
                            setState({
                                ...state,
                                username: evt.target.value,
                            })
                        }
                    />
                    <button
                        onClick={() => updateUser({ username: state.username })}
                    >
                        <i className='fas fa-save' /> Spara
                    </button>
                </div>
            </div>
            <Menu />
        </>
    );
}

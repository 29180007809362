//_.times(N, i => <FormInput key={i} label="Phone Number" />)

export function times(N, fn) {
    var arr = Array(N).fill(0);
    return arr.map((_, idx) => fn(idx));
}

export function random(min, max) {
    return Math.round(Math.random() * (max - min) + min);
}

export function constrain(min, max, N) {
    if (N < min) return min;
    if (N > max) return max;
    return N;
}

export function deepClone(array) {
    if (array) return JSON.parse(JSON.stringify(array));
    return null;
}

export function roundByDate(roundA, roundB) {
    if (roundA.date < roundB.date) return 1;
    if (roundA.date > roundB.date) return -1;
    return 0;
}

export const sum = (acc, curr) => acc + curr;

export function getCoursePar(course) {
    if (course && course.holes)
        return course.holes.map((hole) => hole.par).reduce(sum);
    return 0;
}

export function printScore(score, course) {
    const pars = course?.holes.map((hole) => hole.par);
    const s = score.reduce(
        (acc, scr, idx) => (scr > 0 ? acc + scr - pars[idx] : acc),
        0
    );
    return formatDiffString(s);
}

export function formatDiffString(score) {
    return score < 0 ? `${score}` : score > 0 ? `+${score}` : 'E';
}

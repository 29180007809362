import './Round.scss';
import { sum, printScore } from '../../../utils';

function bio(player) {
    return player.score.some((score) => score === 1);
}

function Player({ player, course }) {
    const score = player.score.reduce(sum);

    return (
        <div className='player'>
            {player.image && (
                <img
                    src={player.image}
                    className='player-image'
                    alt={player.name}
                />
            )}
            <div>
                <div className='player-name'>{player.name}</div>
                <div className='player-score'>
                    {printScore(player.score, course)} ({score})
                </div>
            </div>
        </div>
    );
}

export default function Round({ round, course, onClick }) {
    const months = [
        'jan',
        'feb',
        'mars',
        'apr',
        'maj',
        'juni',
        'juli',
        'aug',
        'sept',
        'okt',
        'nov',
        'dec',
    ];
    const date = new Date(round.date.replace(' ', 'T'));
    // const par = getCoursePar(course);
    return (
        <div className='round' onClick={onClick}>
            {round.players.some(bio) && (
                <div className='bio'>
                    <i className='fas fa-star'></i>
                </div>
            )}
            <div className='course'>{course?.name}</div>
            <div className='date'>
                <div className='day'>{date.getDate()}</div>
                <div className='month'>{months[date.getMonth()]}</div>
                <div className='year'>{date.getFullYear()}</div>
            </div>
            <div className='players'>
                {round.players.map((player, idx) => (
                    <Player key={idx} player={player} course={course}></Player>
                ))}
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import './DateForm.scss';

export default function DateForm({ date, onSave, onCancel }) {
    const [formDate, setFormDate] = useState(date.replace(' ', 'T'));

    useEffect(() => {
        function handleEscape(e) {
            if (e.key === 'Escape') {
                onCancel();
            }
        }
        window.addEventListener('keyup', handleEscape);
        return () => window.removeEventListener('keyup', handleEscape);
    }, [onCancel]);

    return (
        <div className='date-form'>
            <input
                type='datetime-local'
                value={formDate}
                placeholder='Ange datum'
                onChange={(evt) => setFormDate(evt.target.value)}
            />
            <div className='buttons'>
                <button onClick={onCancel}>
                    <i className='fas fa-trash-alt' /> Avbryt
                </button>
                <button onClick={() => onSave(formDate.replace('T', ' '))}>
                    <i className='fas fa-save' /> Spara
                </button>
            </div>
        </div>
    );
}

import React, { useContext } from 'react';
import GolfContext from '../../GolfContext';
import './CoursesView.scss';
import Menu from '../../components/Menu/Menu';

function Courses({ onCourseClick }) {
    const { courses } = useContext(GolfContext);
    return (
        <div className='view-container'>
            <div className='courses'>
                {courses?.map((course, i) => (
                    <div
                        key={course.id}
                        className='course'
                        onClick={() => onCourseClick(course.id)}
                    >
                        <h1>{course.name}</h1>
                        <label>Hål:</label>
                        <span>{course.holes.length}</span> <label>Par:</label>
                        <span>
                            {course.holes.reduce(
                                (acc, hole) => acc + hole.par,
                                0
                            )}
                        </span>{' '}
                        <label>Längd:</label>
                        <span>
                            {course.holes.reduce(
                                (acc, hole) => acc + hole.distance,
                                0
                            )}{' '}
                            m
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default function CoursesView({ courses, onCourseClick }) {
    return (
        <>
            <div className='courses-view'>
                <h1>Banor</h1>
                <Courses
                    courseList={courses}
                    onCourseClick={(courseId) => onCourseClick(courseId)}
                />
            </div>
            <Menu />
        </>
    );
}

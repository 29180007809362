const BASE_URL = 'https://discgolfscore.azurewebsites.net/api';
const SAVE_URL = `${BASE_URL}/SaveRound?code=IU7VEcuDVz4tJagP7Y0fy5iqubi0WwUMq_gtaNcPAHOZAzFu8BXroA==`;
const GET_URL = `${BASE_URL}/GetRounds/{username}?code=1g6LwN50_ptLXRi9XWFNYQejgVblpIjojReMRHqnPJEbAzFue2rzOw==`;
const DEL_URL = `${BASE_URL}/DeleteRound/{id}?code=JkfE-QlBeLZ00NT8cE64sW0AlFUzOjm4t5xBzaBpr9wWAzFuZqwSZw==`;

const CACHE_NAME = 'saved-rounds';

async function del(url = '') {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'error', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    return response.status;
}

async function post(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'error', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        // body: JSON.stringify(data), // body data type must match "Content-Type" header
        body: data, // body data type must match "Content-Type" header
    });
    return response.status;
}

async function get(url = '') {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        redirect: 'error', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    tryStoreInCache(response);

    return response.json(); // parses JSON response into native JavaScript objects
}

export async function GetRounds(username, reload = false, offline = false) {
    try {
        const getUrl = GET_URL.replace('{username}', username);
        if (reload && !offline) {
            return await get(getUrl);
        }
        const response = await tryGetFromCache(getUrl);
        if (!response && !offline) {
            return await get(getUrl);
        }
        return response?.json() || [];
    } catch (err) {
        return [];
    }
}

export async function SaveRound(data) {
    return await post(SAVE_URL, data);
}

export async function DeleteRound(id) {
    return await del(DEL_URL.replace('{id}', id));
}

async function tryGetFromCache(getUrl) {
    try {
        if ('caches' in window) {
            const savedRoundsCache = await caches.open(CACHE_NAME);
            return await savedRoundsCache.match(getUrl);
        }
        return null;
    } catch (err) {
        return null;
    }
}

async function tryStoreInCache(response) {
    try {
        if ('caches' in window) {
            const responseClone = response.clone();
            const savedRoundsCache = await caches.open(CACHE_NAME);
            savedRoundsCache.put(response.url, responseClone);
        }
    } catch (err) {}
}

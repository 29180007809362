import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Menu.scss';

export default function Menu() {
    const currentRoute = useLocation().pathname;
    const classFor = (itemRoute) => {
        return `menu-item ${currentRoute === itemRoute ? 'selected' : ''}`;
    };
    return (
        <div className='menu'>
            <Link to='/user' className={classFor('/user')}>
                <i className='far fa-user'></i>
                <div>Profil</div>
            </Link>
            <Link to='/rounds' className={classFor('/rounds')}>
                <i className='far fa-edit'></i>
                <div>Rundor</div>
            </Link>
            <Link to='/courses' className={classFor('/courses')}>
                <i className='fas fa-road'></i>
                <div>Banor</div>
            </Link>
        </div>
    );
}

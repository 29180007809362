import React, { useContext } from 'react';
import { ReactComponent as OfflineIcon } from './wifi-off.svg';
import './OfflineIndicator.scss';
import GolfContext from '../../GolfContext';

export default function OfflineIndicator() {
    const ctx = useContext(GolfContext);

    if (!ctx.offline) {
        return null;
    }

    return (
        <div className='offline-indicator'>
            <OfflineIcon /> Offline
        </div>
    );
}

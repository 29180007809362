import './CameraButtons.scss';

export default function CameraButtons({ onClick, onCancel, onSwitchCamera }) {
    return (
        <div className='camera-buttons'>
            <div className='capture-button' onClick={onClick}>
                <div className='circle'></div>
                <div className='ring'></div>
            </div>
            <div className='cancel-button' onClick={onCancel}>
                <i className='far fa-times-circle' /> Avbryt
            </div>
            <div className='switch-camera-button' onClick={onSwitchCamera}>
                <i className='fa fa-sync' />
            </div>
        </div>
    );
}

import './App.scss';

import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import CoursesView from './views/courses/CoursesView';
import { GetRounds } from './data/apiClient';
import GolfContext from './GolfContext';
import OfflineIndicator from './components/OfflineIndicator/OfflineIndicator';
import RoundsView from './views/rounds/RoundsView';
import Scorecard from './views/scorecard/Scorecard';
import UserView from './views/user/UserView';
import { roundByDate } from './utils';
import userImage from './images/user.jpg';

const courseIds = [
    'karsonOriginal',
    'karsonKorta32',
    'rudanAlphaGul',
    'tyreso',
    'jarvaYellow',
];

function loadCourse(course) {
    return fetch('/courses/' + course + '.json').then((r) => r.json());
}

export default function App() {
    const navigate = useNavigate();

    const [user, setUser] = useState({ username: '', image: userImage });
    const [courses, setCourses] = useState([]);
    const [currentRound, setCurrentRound] = useState(null);
    const [savedRounds, setSavedRounds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offline, setOffline] = useState(!navigator.onLine);

    function updateUser(updatedUser) {
        const newUser = { ...user, ...updatedUser };
        setUser(newUser);
        localStorage.setItem('user', JSON.stringify(newUser));
    }

    function setSelectedCourse(id) {
        setCurrentRound({ courseId: id });
        navigate('/scorecard');
    }

    function setSelectedRound(round) {
        setCurrentRound(round);
        navigate('/scorecard');
    }

    async function handleOnBack(reloadRounds) {
        setCurrentRound(null);
        navigate('/rounds');
        if (reloadRounds) await forceFetchSavedRounds();
    }

    async function forceFetchSavedRounds() {
        if (offline) return;

        setLoading(true);
        var rounds = await GetRounds(user.username, true, offline);
        rounds.sort(roundByDate);
        setSavedRounds(rounds);
        setLoading(false);
    }

    useEffect(() => {
        function handleOfflineStatusChange() {
            const isOffline = !navigator.onLine;
            setOffline(isOffline);
        }
        window.addEventListener('online', handleOfflineStatusChange);
        window.addEventListener('offline', handleOfflineStatusChange);
        return () => {
            window.removeEventListener('online', handleOfflineStatusChange);
            window.removeEventListener('offline', handleOfflineStatusChange);
        };
    }, []);

    useEffect(() => {
        function fetchAndPreCacheCourseImages(courses) {
            courses.forEach((course) => {
                course.holes.forEach((hole) =>
                    fetch(hole.url, { mode: 'no-cors' })
                );
            });
        }
        async function loadCourses() {
            var courses = await Promise.all(courseIds.map(loadCourse));
            setCourses(courses);
            fetchAndPreCacheCourseImages(courses);
        }
        loadCourses();
    }, []);

    useEffect(() => {
        async function fetchRounds() {
            if (user.username) {
                var rounds = await GetRounds(user.username, false, offline);
                rounds.sort(roundByDate);
                setSavedRounds(rounds);
            }
            setLoading(false);
        }
        setLoading(true);
        fetchRounds();
    }, [user.username, offline]);

    return (
        <GolfContext.Provider
            value={{ user, updateUser, courses, loading, offline }}
        >
            <div className='main-container'>
                <OfflineIndicator />
                <Routes>
                    <Route
                        path='/'
                        element={<Navigate to='/user' replace={true} />}
                    />
                    <Route
                        path='/scorecard'
                        element={
                            <Scorecard
                                round={currentRound || {}}
                                onBack={handleOnBack}
                            />
                        }
                    />
                    <Route
                        path='/rounds'
                        element={
                            <RoundsView
                                savedRounds={savedRounds}
                                courses={courses}
                                onRoundClick={(round) =>
                                    setSelectedRound(round)
                                }
                                onReload={forceFetchSavedRounds}
                            />
                        }
                    />
                    <Route
                        path='/courses'
                        element={
                            <CoursesView onCourseClick={setSelectedCourse} />
                        }
                    />
                    <Route path='/user' element={<UserView />} />
                </Routes>
            </div>
        </GolfContext.Provider>
    );
}

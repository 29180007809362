import React, { useContext } from 'react';
import GolfContext from '../../../GolfContext';

function getDistinctPlayers(rounds) {
    const players = [
        ...new Set(rounds?.map((r) => r.players?.map((p) => p.name)).flat()),
    ];
    return players;
}

export default function RoundsFilters({
    rounds,
    onCourseFilterChange,
    onPLayerFilterChange,
}) {
    const { courses } = useContext(GolfContext);
    const distinctCourseIds = [...new Set(rounds.map((r) => r.courseId))];
    const distinctPlayers = getDistinctPlayers(rounds);

    return (
        <div className='filters'>
            <div>
                <label htmlFor='courses'>Bana</label>
                <select
                    id='courses'
                    onChange={(evt) => onCourseFilterChange(evt.target.value)}
                >
                    <option value=''>-</option>
                    {distinctCourseIds.map((id) => (
                        <option key={id} value={id}>
                            {courses.find((c) => c.id === id)?.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label htmlFor='players'>Spelare</label>
                <select
                    id='players'
                    onChange={(evt) => onPLayerFilterChange(evt.target.value)}
                >
                    <option value=''>-</option>
                    {distinctPlayers.map((name) => (
                        <option key={name} value={name}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

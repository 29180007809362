import React, { useState } from 'react';
import CameraButtons from './CameraButtons/CameraButtons';
import './Camera.scss';

export default function Camera({ onClick, image }) {
    let _video = null;
    let _canvas = null;
    const constraints = {
        user: { video: { facingMode: 'user' } },
        environment: { video: { facingMode: { exact: 'environment' } } },
    };

    const [state, setState] = useState({
        supportsCamera: 'mediaDevices' in navigator,
        enableCamera: false,
        facingMode: 'user',
    });

    const startCamera = () => {
        setState({ ...state, enableCamera: true });
    };

    const stopCamera = () => {
        try {
            _video.srcObject.getVideoTracks().forEach((track) => track.stop());
        } catch (err) {}
        setState({ ...state, enableCamera: false });
    };

    const switchCamera = () => {
        if (state.facingMode === 'user') {
            setState({ ...state, facingMode: 'environment' });
        } else {
            setState({ ...state, facingMode: 'user' });
        }
    };

    const takeImage = () => {
        const vh = _video.videoHeight;
        const vw = _video.videoWidth;

        const size = vh < vw ? vh : vw;

        _canvas.width = size;
        _canvas.height = size;

        _canvas
            .getContext('2d')
            .drawImage(_video, 0, 0, size, size, 0, 0, size, size);

        stopCamera();
        onClick(_canvas.toDataURL());
    };

    const setVideo = (stream) => {
        try {
            _video.srcObject = stream;
        } catch (err) {}
    };

    if (state.supportsCamera && !state.enableCamera) {
        return (
            <div className='image-upload'>
                <div className='image'>
                    {image && <img src={image} alt='user' />}
                </div>
                <i
                    className='fas fa-camera camera-button'
                    onClick={startCamera}
                />
            </div>
        );
    }

    return (
        <div className='image-upload'>
            <div className='camera'>
                <div className='cropper'>
                    <video
                        ref={(c) => {
                            _video = c;
                            if (_video) {
                                navigator.mediaDevices
                                    .getUserMedia(constraints[state.facingMode])
                                    .then(setVideo);
                            }
                        }}
                        controls={false}
                        autoPlay
                        playsInline
                    />
                </div>

                <CameraButtons
                    onClick={takeImage}
                    onCancel={stopCamera}
                    onSwitchCamera={switchCamera}
                />
                <canvas
                    ref={(c) => (_canvas = c)}
                    style={{ display: 'none' }}
                />
            </div>
        </div>
    );
}

import React, { useContext, useEffect, useState } from 'react';
import Round from '../Round/Round';
import RoundsFilters from '../Filter/RoundFilters';
import GolfContext from '../../../GolfContext';
import './RoundsList.scss';

export default function RoundsList({
    filters,
    header,
    rounds,
    whenLoading,
    whenOffline,
    onRoundClick,
}) {
    const { courses, loading, offline } = useContext(GolfContext);
    const [filteredRounds, setFilteredRounds] = useState(rounds);
    const [courseFilter, setCourseFilter] = useState();
    const [playerFilter, setPlayerFilter] = useState();

    useEffect(() => {
        function applyFilters() {
            let filteredRounds = rounds;
            if (courseFilter) {
                filteredRounds = filteredRounds.filter(
                    (r) => r.courseId === courseFilter
                );
            }
            if (playerFilter) {
                filteredRounds = filteredRounds.filter((r) =>
                    r.players.map((p) => p.name).includes(playerFilter)
                );
            }
            setFilteredRounds(filteredRounds);
        }
        applyFilters();
    }, [rounds, courseFilter, playerFilter]);

    function renderRoundsList() {
        if (loading && whenLoading) return null;
        return filteredRounds.map((round, i) => {
            const course = courses?.find((c) => c.id === round.courseId) || {};
            return (
                <div key={'root' + i}>
                    <Round
                        key={i}
                        round={round}
                        course={course}
                        onClick={() => onRoundClick(round)}
                    />
                </div>
            );
        });
    }

    return (
        <div className='rounds'>
            <h1>{header}</h1>
            {loading && whenLoading && <div>{whenLoading}</div>}
            {offline && whenOffline && (!rounds || rounds.length === 0) && (
                <div>{whenOffline}</div>
            )}
            {!loading && rounds && rounds.length === 0 && (
                <div>Rundor saknas</div>
            )}
            {filters && (
                <RoundsFilters
                    rounds={rounds}
                    onCourseFilterChange={(c) => setCourseFilter(c)}
                    onPLayerFilterChange={(p) => setPlayerFilter(p)}
                />
            )}
            {renderRoundsList()}
        </div>
    );
}

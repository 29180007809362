import React, { useState } from 'react';
import './Hole.scss';

export default function Hole({ number, par, distance, mapUrl }) {
    const [showMap, setShowMap] = useState(false);

    function displayMap() {
        setShowMap(true);
    }

    function hideMap(e) {
        e.stopPropagation();
        setShowMap(false);
    }

    return (
        <td className='hole' onClick={displayMap}>
            {showMap === true && (
                <div className='map'>
                    <div className='close-btn' onClick={hideMap}>
                        X
                    </div>
                    <img src={mapUrl} alt={number} />
                </div>
            )}
            <div className='number'>{number}</div>
            <div className='hole-info'>
                <div className='par'>{par}</div>
                <div className='distance'>{distance}m</div>
            </div>
        </td>
    );
}

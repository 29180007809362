import React, { useContext, useEffect, useState } from 'react';
import './RoundsView.scss';
import RoundsList from './RoundsList/RoundsList';
import GolfContext from '../../GolfContext';
import { roundByDate } from '../../utils';
import Menu from '../../components/Menu/Menu';

function ReloadButton({ onClick }) {
    const { offline } = useContext(GolfContext);
    if (offline) return null;
    return <i className='reload-btn fas fa-sync-alt' onClick={onClick}></i>;
}

export default function RoundsView({ savedRounds, onRoundClick, onReload }) {
    const [localRounds, setLocalRounds] = useState([]);
    const { user } = useContext(GolfContext);

    useEffect(() => {
        const keys = Object.keys(localStorage).filter((key) =>
            key.startsWith('round-')
        );
        const rounds = keys
            .map((key) =>
                Object.assign(
                    { id: key },
                    JSON.parse(localStorage.getItem(key))
                )
            )
            .filter((round) => round.username === user.username);
        setLocalRounds(rounds.sort(roundByDate));
    }, [user.username]);

    return (
        <>
            <div className='view-container'>
                <div className='rounds-view'>
                    <RoundsList
                        filters={false}
                        header='Pågående rundor'
                        rounds={localRounds}
                        onRoundClick={(round) => onRoundClick(round)}
                    />
                    <ReloadButton onClick={onReload} />
                    <RoundsList
                        filters={true}
                        header='Avslutade rundor'
                        rounds={savedRounds}
                        whenLoading='Laddar sparade rundor...'
                        whenOffline='Skaffa internet för att hämta avslutade rundor.'
                        onRoundClick={(round) => onRoundClick(round)}
                    />
                </div>
            </div>
            <Menu />
        </>
    );
}

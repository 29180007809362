import React, { useState } from 'react';
import PlayerForm from '../PlayerForm/PlayerForm';
import './AddPlayer.scss';

export default function AddPlayer({ onAdd }) {
    const [isOpen, setIsOpen] = useState(false);

    if (isOpen) {
        return (
            <PlayerForm
                onSave={(name, image) => {
                    setIsOpen(false);
                    onAdd(name, image);
                }}
                onClose={() => setIsOpen(false)}
            />
        );
    }

    return (
        <div className='add-player-btn' onClick={() => setIsOpen(true)}>
            <span className='add-player-btn-icon'>+</span>
        </div>
    );
}

import React from 'react';
import { constrain } from '../../../utils';
import './Score.scss';

const colors = new Map();
colors.set(-3, 'albatross');
colors.set(-2, 'eagle');
colors.set(-1, 'birdie');
colors.set(0, 'par');
colors.set(1, 'bogey');
colors.set(2, 'doublebogey');
colors.set(3, 'triplebogey');

export default function Score({ score, par, terminated, onSetScore }) {
    const color =
        score > 0 ? colors.get(constrain(-3, 3, score - par)) : 'unplayed';

    function handleChange(e) {
        onSetScore(e.target.value);
    }

    return (
        <td className={`score ${color}`}>
            <div className='score-container'>
                <div>{score > 0 ? score : '-'}</div>
                {!terminated && (
                    <select
                        value={score ? score : par}
                        onChange={handleChange}
                        onBlur={handleChange}
                    >
                        <option value='0'>-</option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>7</option>
                        <option value='8'>8</option>
                        <option value='9'>9</option>
                    </select>
                )}
            </div>
        </td>
    );
}
